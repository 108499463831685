import useToggleState from "../../../hooks/useToggleState";
import { BtnSpecial } from "./styled";
import ChevronDownIcon from "../../../components/Icons/ChevronDownIcon";
import PriceComponent from "./price";
import { SpecialProp } from "./types";
import { useSearchParams } from "react-router-dom";

export default function SpecialComponent({
  leagueId,
  matchId,
  tableType,
  homeName,
  awayName,
  leagueName,
  sport,
  priceCount,
}: SpecialProp) {
  const { isToggled, toggle } = useToggleState();
  const [_searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      {priceCount > 0 && (
        <>
          <BtnSpecial
            className={`${
              tableType !== "live" ? `bg-emerald-600` : `bg-orange-400`
            } text-white text-[10px] font-bold p-2 rounded-sm flex items-center w-full justify-center`}
            onClick={() => {
              toggle();
              setSearchParams({
                leagueId: `${leagueId}`,
                matchId: `${matchId}`,
                sport,
                type: tableType,
              });
            }}
          >
            <span className="mr-2" style={{ fontSize: 9 }}>
              MORE
              {priceCount > 0 && (
                <span className="ml-1 text-xs text-gray-600 rounded bg-white px-1">
                  {priceCount}
                </span>
              )}
            </span>
            {isToggled ? (
              <ChevronDownIcon
                className="rotate-180"
                style={{ fontSize: 33 }}
              />
            ) : (
              <ChevronDownIcon style={{ fontSize: 33 }} />
            )}
          </BtnSpecial>
          {isToggled && (
            <PriceComponent
              leagueId={leagueId}
              matchId={matchId}
              sportType={sport.toUpperCase()}
              tableType={tableType}
              homeName={homeName}
              awayName={awayName}
              leagueName={leagueName}
              sport={sport}
              priceCount={priceCount}
            />
          )}
        </>
      )}
    </>
  );
}
