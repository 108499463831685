import axios from "axios";
import { CONFIG } from "../../config";

export const pinFavorite = async (
  memberId: string,
  update: { id: string; name: string },
  type: "LEAGUE" | "TEAM"
) => {
  const result = await axios.post(`${CONFIG.API_URL}/c/m/favorite`, {
    id: memberId,
    update,
    type,
  });

  return result?.data;
};
