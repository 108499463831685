import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SportName from "./SportName";
import { SPORT_LISTS } from "./config";
import { Button } from "./styled";
import NumberOfMatch from "./NumberOfMatch";
import useSportsBar from "./useSportsBar";
import SportBarService from "../../services/sportBar";

const sportBarService = new SportBarService();
const accordionId = sportBarService.getAccordionId();
// const buttonId = sportBarService.getButtonId();

const SportsBarDesktop: FC = () => {
  const { numberOfMatchType } = useSportsBar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      <Button
        // id={buttonId}
        // aria-expanded="true"
        className="p-3 flex w-full justify-items-center"
        // className="p-3 flex w-full justify-items-center accordion-button"
        // data-bs-target={`#${accordionId}`}
        // data-bs-toggle="collapse"
        // type="button"
        // aria-controls={accordionId}
        noBorder
      >
        <SportName
          iconSrc={`${process.env.PUBLIC_URL}/allSport.png`}
          name={t("List of Sports")}
        />
      </Button>
      <ul
        className="accordion collapse show accordion-flush"
        id={accordionId}
      >
        {SPORT_LISTS.map((sport) => {
          const collapseId = `collapse-${sport.name}`;
          const headingId = `heading-${sport.name}`;
          const hasCollapse = Boolean(sport?.collapse?.length);

          return (
            <li key={sport.name} className="text-left text-sm accordion-item">
              {hasCollapse ? (
                <>
                  <Button
                    aria-expanded="false"
                    className="p-3 collapsed flex w-full justify-items-center accordion-button"
                    data-bs-target={`#${collapseId}`}
                    data-bs-toggle="collapse"
                    id={headingId}
                    type="button"
                    aria-controls={collapseId}
                    marginArrow="8px"
                  >
                    <SportName
                      iconSrc={sport.icon}
                      name={t(sport.name.replace("-", " "))}
                    />
                    <NumberOfMatch
                      className="ml-auto"
                      isLoading={numberOfMatchType.isLoading}
                      data={numberOfMatchType.data}
                      keyData={sport.keyData}
                    />
                  </Button>
                  <ul
                    aria-labelledby={headingId}
                    className="collapse accordion-collapse pl-7"
                    data-bs-parent={`#${accordionId}`}
                    id={collapseId}
                  >
                    {sport?.collapse?.map((item) => (
                      <li key={item.name}>
                        <Button
                          className="flex p-3 w-full"
                          onClick={() => {
                            navigate(`/${sport.routeTo}/${item.routeTo}`);
                          }}
                        >
                          <div>{t(item.name)}</div>
                          <NumberOfMatch
                            className="ml-auto mr-6"
                            isLoading={numberOfMatchType.isLoading}
                            data={numberOfMatchType.data}
                            keyData={item.keyData}
                          />
                        </Button>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <Button
                  className="p-3 flex w-full"
                  disabled={!sport.routeTo}
                  onClick={() => {
                    navigate(`/${sport.routeTo}`);
                  }}
                >
                  <SportName iconSrc={sport.icon} name={t(sport.name)} />
                  {sport.isComingSoon ? (
                    <div className="ml-auto bg-gray-200 rounded-md p-1 text-xs">
                      {t("Coming Soon")}
                    </div>
                  ) : (
                    <NumberOfMatch
                      className="ml-auto mr-6"
                      isLoading={numberOfMatchType.isLoading}
                      data={numberOfMatchType.data}
                      keyData={sport.keyData}
                    />
                  )}
                </Button>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SportsBarDesktop;
