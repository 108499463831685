import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import SportTable from "../../contrainers/SportTable";
import SportTableMobile from "../../contrainers/SportTable/SportTableMobile";
import mutation from "../../mutation";
import BetTypeSelectorMobile from "../../contrainers/BetTypeSelector/BetTypeSelectorMobile";
import useScreen from "../../hooks/useScreen";
// import TopEvent from "../../contrainers/TopEvent";

const FootballPage = () => {
  const { sport = "football", type = "today" } = useParams<{
    sport:
      | "football"
      | "basketball"
      | "volleyball"
      | "tabletennis"
      | "handball";
    type: "today" | "live" | "early";
  }>();
  const queryClient = useQueryClient();
  const updateBetType = useMutation(mutation.updateBetType, {
    onSuccess: (value) => {
      queryClient.setQueryData(["betType"], value);
    },
  });

  const updateOddsType = useMutation(mutation.updateOddsType, {
    onSuccess: (value) => {
      queryClient.setQueryData(["oddsType"], value);
    },
  });

  const updateBetTicketInfoPayload = useMutation(
    mutation.updateBetTicketInfoPayload,
    {
      onSuccess: (payload) => {
        queryClient.setQueryData(["betTicketInfoPayload"], payload);
      },
    }
  );

  const { isDesktop } = useScreen();

  useEffect(() => {
    updateBetTicketInfoPayload.mutate([]);
    updateBetType.mutate("normal");
    updateOddsType.mutate(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mb-10 desktop:ml-3 desktop:mr-3">
      {isDesktop ? (
        <SportTable title={`${sport}/${type}`} type={type} sport={sport} />
      ) : (
        <>
          {/* {sport === "football" && <TopEvent sport="football" />} */}
          <BetTypeSelectorMobile
            sport={sport}
            type={type}
            title={`${sport}/${type}`}>
            <SportTableMobile
              title={`${sport}/${type}`}
              type={type}
              sport={sport}
            />
          </BetTypeSelectorMobile>
        </>
      )}
    </div>
  );
};

export default FootballPage;
