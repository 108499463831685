import Drawer from "@mui/material/Drawer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ComputerIcon from "@mui/icons-material/Computer";
import MobileIcon from "@mui/icons-material/PhoneAndroid";
import { FC, useMemo, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "../../components/Button";
import { useNavigate } from "react-router-dom";
import Badge from "../../components/Badge";
import { Wrapper, BalanceWrapper, Background } from "./styled";
import query from "../../query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { suffixNumber } from "../../utils/suffixNumber";
import BetsTabs from "../SideNav/BetsTabs";
import produce from "immer";
import mutation from "../../mutation";
import classNames from "classnames";
import useIsForceScreen from "../../hooks/useIsForceScreen";

export type FooterMenuProps = {};

const FooterMenu: FC = () => {
  const isIframe = window?.localStorage.getItem("isIframe") === "true";
  const isForceDesktop =
    window?.localStorage.getItem("isForceDesktop") === "true";
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const isOddsTypeToggleQuery = query.isOddsTypeToggle();
  const isOddsTypeToggle = useQuery(
    isOddsTypeToggleQuery.key,
    isOddsTypeToggleQuery.queryFn,
    {
      refetchOnMount: false,
      keepPreviousData: true,
    }
  );

  const isLanguageToggleQuery = query.isLanguageToggle();
  const isLanguageToggle = useQuery(
    isLanguageToggleQuery.key,
    isLanguageToggleQuery.queryFn,
    {
      refetchOnMount: false,
      keepPreviousData: true,
    }
  );

  const isMenuFooterToggleQuery = query.isMenuFooterToggle();
  const isMenuFooterToggle = useQuery(
    isMenuFooterToggleQuery.key,
    isMenuFooterToggleQuery.queryFn,
    {
      refetchOnMount: false,
      keepPreviousData: true,
    }
  );

  const isBetSlipToggleQuery = query.isBetSlipToggle();
  const isBetSlipToggle = useQuery(
    isBetSlipToggleQuery.key,
    isBetSlipToggleQuery.queryFn,
    {
      refetchOnMount: false,
      keepPreviousData: true,
    }
  );

  const oddsTypeQuery = query.oddsType();
  const oddsType = useQuery(oddsTypeQuery.key, oddsTypeQuery.queryFn, {
    refetchOnMount: false,
    keepPreviousData: true,
  });

  const betTicketInfoPayloadQuery = query.betTicketInfoPayload();
  const betTicketInfoPayload = useQuery(
    betTicketInfoPayloadQuery.key,
    betTicketInfoPayloadQuery.queryFn,
    { refetchOnMount: false, keepPreviousData: true }
  );

  const getBetTicketsQuery = query.getBetTickets({
    page: 1,
    limit: 10,
    type: ["normal", "step", "comboStep"],
    criteriaDate: "ALL",
  });

  const getBetTickets = useQuery(
    getBetTicketsQuery.key,
    getBetTicketsQuery.queryFn
  );

  const queryClient = useQueryClient();
  const updateCurrentTabIndex = useMutation(mutation.updateCurrentTabIndex, {
    onSuccess: (index) => {
      queryClient.setQueryData(["currentTabIndex"], index);
    },
  });

  const updateIsBetSlipToggle = useMutation(mutation.updateIsBetSlipToggle, {
    onSuccess: (newIsBetSlipToggle) => {
      queryClient.setQueryData(["isBetSlipToggle"], newIsBetSlipToggle);
    },
  });

  const updateIsMenuFooterToggle = useMutation(
    mutation.updateIsMenuFooterToggle,
    {
      onSuccess: (newIsMenuFooterToggle) => {
        queryClient.setQueryData(["isMenuFooterToggle"], newIsMenuFooterToggle);
      },
    }
  );

  const updateIsLanguageToggle = useMutation(mutation.updateIsLanguageToggle, {
    onSuccess: (newIsLanguageToggle) => {
      queryClient.setQueryData(["isLanguageToggle"], newIsLanguageToggle);
    },
  });

  const undateIsOddsTypeToggle = useMutation(mutation.undateIsOddsTypeToggle, {
    onSuccess: (newIsOddsTypeToggle) => {
      queryClient.setQueryData(["isOddsTypeToggle"], newIsOddsTypeToggle);
    },
  });

  const undateOddsType = useMutation(mutation.updateOddsType, {
    onSuccess: (newOddsType) => {
      queryClient.setQueryData(["oddsType"], newOddsType);
    },
  });

  const menuFooterToggle = useCallback(() => {
    updateIsMenuFooterToggle.mutate(!isMenuFooterToggle.data);
  }, [isMenuFooterToggle.data, updateIsMenuFooterToggle]);

  const menuLists = useRef([
    {
      label: "Bet Slip",
      icon: "/svg/bet-slip.svg",
      onClick: () => {
        updateCurrentTabIndex.mutate(0);
        updateIsBetSlipToggle.mutate(true);
      },
      badgeNo: 0,
    },
    {
      label: "My Bets",
      icon: "/svg/my-bets.svg",
      onClick: () => {
        updateCurrentTabIndex.mutate(1);
        updateIsBetSlipToggle.mutate(true);
      },
      badgeNo: 0,
    },
    {
      label: "Statement",
      icon: "/svg/statement.svg",
      onClick: () => {
        navigate("/statement");
      },
    },
    {
      label: "More",
      icon: "/svg/more.svg",
      onClick: () => {
        updateIsMenuFooterToggle.mutate(true);
      },
    },
  ]);

  const updatedMenu = useMemo(
    () =>
      produce(menuLists.current, (draft) => {
        draft[0].badgeNo = betTicketInfoPayload.data?.length || 0;
        draft[1].badgeNo = getBetTickets.data?.totalDocs || 0;
      }),

    [getBetTickets.data, betTicketInfoPayload.data]
  );

  const profile = query.profile();
  const { data, isLoading } = useQuery(profile.key, profile.queryFn, {
    staleTime: 10000,
  });
  const currency = data?.cur[0];
  const isBalanceShown = !isLoading && currency && data?.balance;

  const showBalance = () => {
    if (!isBalanceShown) return "";

    const balance = data?.balance?.[currency]
      ? Number(data?.balance?.[currency].balance.$numberDecimal)
      : Number(data?.balance);

    if (Number.isNaN(balance)) return "";

    return `${currency} ${suffixNumber(balance)}`;
  };

  const { forceSwitchScreen } = useIsForceScreen();

  return (
    <>
      <Wrapper>
        {updatedMenu.map((list) => {
          return (
            <button
              key={list.label}
              onClick={list.onClick}
              className="flex items-center justify-center flex-col text-xs p-1 relative"
              style={{ minWidth: "60px" }}
            >
              {Boolean(list?.badgeNo) && (
                <Badge className="text-xs absolute top-0 right-0">
                  {list.badgeNo}
                </Badge>
              )}
              <img
                src={list.icon}
                alt={list.label}
                width="20px"
                height="20px"
                className="mb-1"
              />
              {t(list.label)}
            </button>
          );
        })}
      </Wrapper>
      <Drawer
        anchor="right"
        open={isBetSlipToggle.data}
        onClose={menuFooterToggle}
      >
        <Background className="w-screen h-screen text-sm">
          <div className="flex gap-x-2">
            <button
              onClick={() => {
                updateIsBetSlipToggle.mutate(!isBetSlipToggle.data);
              }}
              className="py-3 px-5 bg-white rounded"
            >
              <KeyboardBackspaceIcon
                className="mr-1"
                style={{ fontSize: 18 }}
              />
              {t("Back")}
            </button>
            <BalanceWrapper className="p-3 flex-1 flex items-center justify-between rounded">
              <span>Your balance:</span>
              <b>{showBalance()} THB</b>
            </BalanceWrapper>
          </div>
          <div>
            <BetsTabs />
          </div>
        </Background>
      </Drawer>

      <Drawer
        anchor="bottom"
        open={isMenuFooterToggle.data}
        onClose={() => {
          updateIsMenuFooterToggle.mutate(!isMenuFooterToggle.data);
        }}
      >
        <Background className="w-screen h-screen text-sm">
          <div className="flex bg-white">
            <button onClick={menuFooterToggle} className="py-3 px-5 rounded">
              <KeyboardBackspaceIcon
                className="mr-1 bg-yellow-300 rounded p-1 box-content"
                style={{ fontSize: 18 }}
              />
              {t("Back")}
            </button>
          </div>
          <div className="flex gap-2 p-2">
            <button
              className="flex-1 py-4 bg-white rounded"
              onClick={menuFooterToggle}
            >
              <img
                src="/svg/account.svg"
                alt="account"
                height={26}
                width={26}
                className="mx-auto mb-2"
              />
              {t("Account Info")}
            </button>
            <button
              className="flex-1 bg-white rounded"
              onClick={() => {
                navigate("/results");
                menuFooterToggle();
              }}
            >
              <img
                src="/svg/result.svg"
                alt="account"
                height="26px"
                className="mx-auto mb-1"
              />
              {t("Results")}
            </button>
          </div>

          <div className="flex flex-col mb-4">
            <button
              className="flex justify-between bg-white p-3 border-b"
              onClick={() => {
                updateIsLanguageToggle.mutate(true);
              }}
            >
              <b>{t("Languages")}</b>
              <u className="text-blue-800 underline-offset-4">
                {t(i18n.language === "en" ? "English" : "Thai")}
                <ArrowDropDownIcon />
              </u>
            </button>
            <button
              className="flex justify-between bg-white p-3 border-b"
              onClick={() => {
                undateIsOddsTypeToggle.mutate(true);
              }}
            >
              <b>{t("Odds type")}</b>
              <u className="text-blue-800 underline-offset-4">
                {t(
                  oddsType.data === 0
                    ? "Malay"
                    : oddsType.data === 1
                    ? "Hongkong"
                    : "Euro"
                )}
                <ArrowDropDownIcon />
              </u>
            </button>
            {!isIframe && !isForceDesktop && (
              <button
                className="flex items-center bg-white p-3 border-b"
                onClick={forceSwitchScreen}
              >
                <b>
                  {t("Change")} <MobileIcon /> {t("to")} <ComputerIcon />
                </b>
              </button>
            )}
          </div>

          <div className="p-2">
            <Button
              buttonType={ButtonType.SECONDARY}
              className="w-full font-bold p-2"
              onClick={menuFooterToggle}
            >
              {t("Sign Out")}
            </Button>
          </div>
        </Background>
      </Drawer>

      <Drawer
        anchor="bottom"
        open={isLanguageToggle.data}
        onClose={() => {
          updateIsLanguageToggle.mutate(!isLanguageToggle.data);
        }}
      >
        <button
          className={classNames("border-b border-gray-200 p-3", {
            "font-bold": i18n.language === "en",
          })}
          onClick={() => {
            i18n.changeLanguage("en");
            updateIsLanguageToggle.mutate(false);
          }}
        >
          {t("English")}
        </button>
        <button
          className={classNames("border-b border-gray-200 p-3", {
            "font-bold": i18n.language === "th",
          })}
          onClick={() => {
            i18n.changeLanguage("th");
            updateIsLanguageToggle.mutate(false);
          }}
        >
          {t("Thai")}
        </button>
      </Drawer>

      <Drawer
        anchor="bottom"
        open={isOddsTypeToggle.data}
        onClose={() => {
          undateIsOddsTypeToggle.mutate(false);
        }}
      >
        <button
          className={classNames("border-b border-gray-200 p-3", {
            "font-bold": oddsType.data === 0,
          })}
          onClick={() => {
            undateOddsType.mutate(0);
            undateIsOddsTypeToggle.mutate(false);
          }}
        >
          {t("Malay")}
        </button>
        <button
          className={classNames("border-b border-gray-200 p-3", {
            "font-bold": oddsType.data === 1,
          })}
          onClick={() => {
            undateOddsType.mutate(1);
            undateIsOddsTypeToggle.mutate(false);
          }}
        >
          {t("Hongkong")}
        </button>
        <button
          className={classNames("border-b border-gray-200 p-3", {
            "font-bold": oddsType.data === 2,
          })}
          onClick={() => {
            undateOddsType.mutate(2);
            undateIsOddsTypeToggle.mutate(false);
          }}
        >
          {t("Euro")}
        </button>
      </Drawer>
    </>
  );
};

export default FooterMenu;
