import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import query from "../query";
import mutation from "../mutation";
import { useCallback } from "react";

const useIsForceScreen = (onSwitch?: () => void) => {
  const queryClient = useQueryClient();
  const getIsForceScreen = query.isForceScreen();
  const isForceScreen = useQuery(
    getIsForceScreen.key,
    getIsForceScreen.queryFn,
    {
      refetchOnMount: false,
      keepPreviousData: true,
    }
  );
  const updateIsForceScreen = useMutation(mutation.updateIsForceScreen, {
    onSuccess: (data) => {
      queryClient.setQueryData(["isForceScreen"], data);
    },
  });

  const forceSwitchScreen = useCallback(() => {
    onSwitch?.();
    updateIsForceScreen.mutate(!isForceScreen.data);
   
  }, [isForceScreen.data, onSwitch, updateIsForceScreen]);

  return {
    isForceScreen: isForceScreen.data,
    forceSwitchScreen,
  };
};

export default useIsForceScreen;
