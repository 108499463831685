import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FC, memo, useRef, useEffect, useCallback } from "react";
import Modal from "@mui/material/Modal";
import BettingTeamName from "../../components/BettingTeamName";
import XMarkIcon from "../../components/Icons/XMarkIcon";
import Loading from "../../components/Loading";
import Refresh from "../../components/Refresh";
import { OddPrice, TeamName } from "../../components/Typography";
import query from "../../query";
import {
  BetTicketInfoForDisplay,
  BetTicketInfoPayload,
} from "../../types/betTicket";
import { convertOdds } from "../../utils/convertOdds";
import {
  BetTicketInfoType,
  BetTicketInfoWrapper,
  LeagueName,
  PriceInfo,
} from "./styled";
import useToggleState from "../../hooks/useToggleState";
import { Button } from "../../components/Button";
import betTypeDisplay from "../../utils/betTypeDisplay";

export type MatchProps = BetTicketInfoPayload &
  BetTicketInfoForDisplay & {
    oddsType: number;
    onRemove?: (matchId: string) => void;
  };

const Match: FC<MatchProps> = ({
  type,
  table,
  sportType,
  betType,
  option = 0,
  matchId,
  leagueId,
  riskLevel,
  //
  league,
  isHomeGood,
  isAwayGood,
  home,
  away,
  //
  oddsType,
  specifiers,
  outcomeId,
  marketId,
  onRemove = () => {},
}) => {
  const matchEl = useRef<HTMLDivElement>(null);
  const { isToggled, toggle } = useToggleState();

  useEffect(() => {
    if (matchEl.current) {
      matchEl.current.scrollIntoView();
    }
  }, [matchId, type, riskLevel, option]);

  const betTicketInfoQuery = query.betTicketInfo({
    type,
    table,
    sportType,
    betType,
    option,
    matchId,
    leagueId,
    riskLevel,
    specifiers,
    outcomeId,
    marketId,
  });
  const queryClient = useQueryClient();
  const betTicketInfo = useQuery(
    betTicketInfoQuery.key,
    betTicketInfoQuery.queryFn,
    {
      refetchIntervalInBackground: false,
      cacheTime: 3 * 1000, //ms,
      onError: () => {
        if (!isToggled) {
          toggle();
        }
      },
    }
  );

  const handleClose = useCallback(() => {
    toggle();
    const betTicketInfoPayloads =
      queryClient.getQueryData<
        (BetTicketInfoPayload & BetTicketInfoForDisplay)[]
      >(["betTicketInfoPayload"]) ?? [];
    queryClient.setQueryData(
      ["betTicketInfoPayload"],
      betTicketInfoPayloads.filter((betTicketInfoPayload) => {
        return (
          betTicketInfoPayload.matchId !== matchId &&
          betTicketInfoPayload.betType !== betType
        );
      })
    );
  }, [betType, matchId, queryClient, toggle]);

  const formattedBetType = betType === "ah" ? "HDP" : betType.toUpperCase();

  const mappedTeamWithOption = {
    name: betTypeDisplay(option, betType, {
      h: home,
      a: away,
    }),
    isGoodTeam: true,
  };

  const odds = ["x12", "x121st"].includes(betType)
    ? Number(betTicketInfo.data?.price).toFixed(2)
    : convertOdds(Number(betTicketInfo.data?.price), oddsType);

  return (
    <div ref={matchEl}>
      <BetTicketInfoType isLive={table === "live"}>
        <b className="text-xs">{`${sportType} - ${formattedBetType}`}</b>
        <div className="flex">
          <Refresh
            key={`${matchId}-${leagueId}-${riskLevel}-${betType}-${option}`}
            noLabel
            isLoading={betTicketInfo.isLoading || isToggled}
            onClick={() => {
              betTicketInfo.refetch();
            }}
            initialCounter={3}
          />
          <button
            className="p-1"
            type="button"
            onClick={() => {
              onRemove(matchId);
            }}>
            <XMarkIcon />
          </button>
        </div>
      </BetTicketInfoType>
      <BetTicketInfoWrapper
        isLive={table === "live"}
        className="p-4 text-center">
        <TeamName
          className="text-sm flex justify-center items-center"
          isGoodTeam={isHomeGood}>
          <img
            src={`https://data.askmescore.live/static/images/teams/${betTicketInfo.data?.n?.fhid}?size=large`}
            alt={home}
            className="w-5 h-5 mr-1"
            onError={(event) => {
              event.currentTarget.onerror = null;
              event.currentTarget.src = `${process.env.PUBLIC_URL}/team.svg`;
            }}
          />
          {home}
        </TeamName>
        <b>vs</b>
        <TeamName
          className="text-sm flex justify-center items-center"
          isGoodTeam={isAwayGood}>
          <img
            src={`https://data.askmescore.live/static/images/teams/${betTicketInfo.data?.n?.faid}?size=large`}
            alt={away}
            className="w-5 h-5 mr-1"
            onError={(event) => {
              event.currentTarget.onerror = null;
              event.currentTarget.src = `${process.env.PUBLIC_URL}/team.svg`;
            }}
          />
          {away}
        </TeamName>
        <LeagueName className="p-2 text-xs font-bold">{league}</LeagueName>
        <PriceInfo isLive={table === "live"}>
          {betTicketInfo.isLoading ? (
            <Loading className="m-auto" />
          ) : (
            <>
              <div>{`${formattedBetType}${
                !!betTicketInfo.data?.value
                  ? `[${betTicketInfo.data?.value}]`
                  : ""
              }`}</div>
              <div className="flex justify-center items-baseline">
                <BettingTeamName
                  betPriceType={betType}
                  option={option}
                  bettingTeamName={mappedTeamWithOption.name}
                  isGoodTeam={mappedTeamWithOption.isGoodTeam}
                />
                <b className="mx-1">​@</b>
                <OddPrice isRedOdd={odds.includes("-")}>{odds}</OddPrice>
              </div>
            </>
          )}
        </PriceInfo>
      </BetTicketInfoWrapper>
      <Modal open={isToggled} onClose={handleClose}>
        <div
          className="rounded-md bg-white absolute flex flex-col justify-center items-center p-4"
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
          }}>
          <h3>
            This <b>{`${betType}`.toUpperCase()}</b> market was closed.
          </h3>
          <div className="flex items-center gap-x-2 mb-5">
            <TeamName className="text-sm flex gap-x-2" isGoodTeam={isHomeGood}>
              <img
                src={`https://data.askmescore.live/static/images/teams/${betTicketInfo.data?.n?.fhid}?size=large`}
                alt={home}
                className="w-5 h-5"
                onError={(event) => {
                  event.currentTarget.onerror = null;
                  event.currentTarget.src = `${process.env.PUBLIC_URL}/team.svg`;
                }}
              />
              {home}
            </TeamName>
            <b>vs</b>
            <TeamName className="text-sm flex gap-x-2" isGoodTeam={isAwayGood}>
              <img
                src={`https://data.askmescore.live/static/images/teams/${betTicketInfo.data?.n?.faid}?size=large`}
                alt={away}
                className="w-5 h-5"
                onError={(event) => {
                  event.currentTarget.onerror = null;
                  event.currentTarget.src = `${process.env.PUBLIC_URL}/team.svg`;
                }}
              />
              {away}
            </TeamName>
          </div>
          <Button className="px-3 py-2" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default memo(Match);
