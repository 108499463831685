import { FC, HTMLAttributes } from "react";

const ChevronDownIcon: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => (
  <svg
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    width="1em"
    height="1em"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
    />
  </svg>
);

export default ChevronDownIcon;
