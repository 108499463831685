import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import "react-toastify/ReactToastify.min.css";

import { THEME } from "./constants/theme";
import AnnouncementBar from "./contrainers/AnnouncementBar";
import TopNav from "./contrainers/TopNav";
import SideNav from "./contrainers/SideNav";
import StatusBar from "./contrainers/StatusBar";
import ProfileProvider from "./contrainers/ProfileProvider";
import SportsBarMobile from "./contrainers/SportsBar/SportsBarMobile";
import FooterMenu from "./contrainers/FooterMenu";
import { Background, TopNavWrapper, FooterMenuWrapper } from "./styled";
import { Router } from "./Router";
import "./App.css";
import "./i18n/index.ts";
import RightBar from "./contrainers/RightBar";
import useScreen from "./hooks/useScreen";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Display = () => {
  const { isDesktopDefault, isForceScreen, isDesktop } = useScreen();
  const isForceDesktop =
    window?.localStorage.getItem("isForceDesktop") === "true";

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={THEME.global.Secondary} />

        {(isForceScreen && !isDesktopDefault) || isForceDesktop ? (
          <meta name="viewport" content="width=1440, initial-scale=0.4" />
        ) : (
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        )}
      </Helmet>

      <Background>
        <TopNavWrapper>
          <TopNav />
          {!isDesktop && <SportsBarMobile />}
          <AnnouncementBar />
          {isDesktop && <StatusBar />}
        </TopNavWrapper>
        {isDesktop && <SideNav />}
        <div className="relative pb-16 overflow-x-auto desktop:pl-60 desktop:pr-60 desktop:pt-40 mobile:pt-36 min-h-screen">
          <Router />
        </div>
        {isDesktop && <RightBar />}
        {!isDesktop && (
          <FooterMenuWrapper>
            <FooterMenu />
          </FooterMenuWrapper>
        )}
      </Background>
    </>
  );
};

const App = () => {
  return (
    <div className="App">
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ProfileProvider>
          <ThemeProvider theme={THEME}>
            <Display />
          </ThemeProvider>
        </ProfileProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
