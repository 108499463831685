import { FC, useMemo, memo } from "react";
import { BetPrice } from "../../../../types/feed";
import { format, mapHeader, changeSubHeader } from "./format";
import MarketWrapper from "./MarketWrapper";
import Market from "./Market";

export type BetPriceTableProps = {
  data: BetPrice[];
  homeName: string;
  awayName: string;
  leagueName: string;
  matchId: string;
  leagueId: string;
  sportType: string;
  tableType: string;
  betType: string;
  fhid?: string | number;
  faid?: string | number;
};

const BetPriceTable: FC<BetPriceTableProps> = ({
  data = [],
  homeName,
  awayName,
  leagueName,
  matchId,
  leagueId,
  sportType,
  tableType,
  betType,
  fhid,
  faid,
}) => {
  const formatted = useMemo(
    () => format(data, { homeName, awayName }),
    [data, homeName, awayName]
  );

  return (
    <div
      className="md:grid md:grid-cols-2 md:gap-2"
      style={{
        borderTop: "1px solid rgb(221, 221, 221)",
      }}>
      {Object.keys(formatted).map((key: string) => {
        if (formatted[key].length === 0) {
          return null;
        }

        let header = mapHeader[key];

        if (header?.isShownTeamName) {
          header = changeSubHeader(header, homeName, awayName);
        }

        return (
          <MarketWrapper key={key} title={header.title}>
            <Market
              data={formatted[key]}
              header={header}
              marketType={key}
              leagueId={leagueId}
              matchId={matchId}
              sportType={sportType}
              tableType={tableType}
              betType={betType}
              homeName={homeName}
              awayName={awayName}
              leagueName={leagueName}
              fhid={fhid}
              faid={faid}
            />
          </MarketWrapper>
        );
      })}
    </div>
  );
};

export default memo(BetPriceTable);
