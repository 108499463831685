import { useQuery } from "@tanstack/react-query";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { TeamName } from "../../../components/Typography";
import query from "../../../query";
import { isGoodTeam } from "../../../utils/isGoodTeam";
import Button from "./NewButton";
import useOddChangeDetector from "../../../hooks/useOddChangeDetector";
import { convertOdds } from "../../../utils/convertOdds";
import { convertHDP } from "../../../utils/convertHDP";
import { cleanCornerId } from "../../../utils/cleanConnerId";
import Badge from "../../../components/Badge";
import classnames from "classnames";
import { useSearchParams } from "react-router-dom";

export type TableMobileProps = {
  match: any;
  type: "live" | "today" | "early";
  onClickOddPrice?: (payload1: any) => (payload2: any) => void;
  onClickMore?: () => void;
};

const NewMatchTable: FC<
  TableMobileProps & {
    sport:
      | "football"
      | "basketball"
      | "volleyball"
      | "tabletennis"
      | "handball"
      | "icehockey"
      | "tennis"
      | "snooker"
      | "baseball"
      | "rugby"
      | "americanfootball"
      | "badminton";
    leagueId: string;
  }
> = ({ sport, match, type, onClickOddPrice, leagueId, onClickMore }) => {
  const { i18n, t } = useTranslation();
  const [_searchParams, setSearchParams] = useSearchParams();
  const [isCollapse, setIsCollapse] = useState(false);
  const isLive = type === "live";
  const isToday = type === "today";
  const betPriceKey = isLive ? "bpl" : "bp";
  const hdp = match?.[betPriceKey]?.[0]?.ah;
  const betTicketInfoPayloadQuery = query.betTicketInfoPayload();
  const betTicketInfoPayload = useQuery(
    betTicketInfoPayloadQuery.key,
    betTicketInfoPayloadQuery.queryFn,
    { refetchOnMount: false, keepPreviousData: true }
  );

  const oddsTypeQuery = query.oddsType();
  const oddsType = useQuery(oddsTypeQuery.key, oddsTypeQuery.queryFn, {
    refetchOnMount: false,
    keepPreviousData: true,
  });

  const { homePriceChangedType, awayPriceChangedType } = useOddChangeDetector({
    homeHandicap: hdp?.h || "",
    awayHandicap: hdp?.a || "",
    homePrice: hdp?.hp || "0",
    awayPrice: hdp?.ap || "0",
    type: "ah",
  });

  const handle = onClickOddPrice?.({
    matchId: `${match.k}`,
    homeName: match.n[i18n.language as string]?.h,
    awayName: match.n[i18n.language as string]?.a,
    fhid: match.n.fhid,
    faid: match.n.faid,
  });

  return (
    <>
      <div className="flex flex-col bg-white mb-2 mx-2 rounded-md">
        <div className="w-full">
          <div
            style={{ borderColor: "inherit" }}
            className="flex pl-2 w-full border-b">
            <div className="flex-1 flex flex-col py-1 gap-y-1">
              <div className="flex items-center w-full">
                {isLive && (
                  <b
                    className={classnames("mr-2", {
                      "text-green-600": Number(match.i.h) > Number(match.i.a),
                    })}>
                    {match.i.h}
                  </b>
                )}
                <div className="flex flex-1 items-center gap-x-2">
                  <img
                    src={`https://data.askmescore.live/static/images/teams/${match.n.fhid}?size=large`}
                    alt=""
                    className="w-5 h-5"
                    onError={(event) => {
                      event.currentTarget.onerror = null;
                      event.currentTarget.src = `${process.env.PUBLIC_URL}/team.svg`;
                    }}
                  />

                  <TeamName
                    className="flex items-center text-sm pr-2"
                    isGoodTeam={isGoodTeam(match?.[betPriceKey]?.[0]?.ah?.h)}>
                    {match.n[i18n.language as string]?.h}
                  </TeamName>
                  {!!match?.i?.hrc &&
                    match?.i?.hrc !== "NaN" &&
                    match?.i?.arc !== "0" && (
                      <span className="">
                        {Array.from({
                          length: Number(match?.i?.hrc),
                        }).map((_each, index) => (
                          <img
                            key={index}
                            className="inline"
                            src="/red.png"
                            alt="red-card"
                            width={16}
                          />
                        ))}
                      </span>
                    )}
                </div>
              </div>
              <div className="flex items-center w-full">
                {isLive && (
                  <b
                    className={classnames("mr-2", {
                      "text-green-600": Number(match.i.a) > Number(match.i.h),
                    })}>
                    {match.i.a}
                  </b>
                )}
                <div className="flex flex-1 items-center  gap-x-2">
                  <img
                    src={`https://data.askmescore.live/static/images/teams/${match.n.faid}?size=large`}
                    alt=""
                    className="w-5 h-5"
                    onError={(event) => {
                      event.currentTarget.onerror = null;
                      event.currentTarget.src = `${process.env.PUBLIC_URL}/team.svg`;
                    }}
                  />
                  <TeamName
                    className="flex items-center text-sm pr-2"
                    isGoodTeam={isGoodTeam(match?.[betPriceKey]?.[0]?.ah?.a)}>
                    {match.n[i18n.language as string].a}
                  </TeamName>

                  {!!match?.i?.arc &&
                    match?.i?.arc !== "NaN" &&
                    match?.i?.arc !== "0" && (
                      <span className="">
                        {Array.from({
                          length: Number(match?.i?.arc),
                        }).map((_each, index) => (
                          <img
                            key={index}
                            className="inline"
                            src="/red.png"
                            alt="red-card"
                            width={16}
                          />
                        ))}
                      </span>
                    )}
                </div>
              </div>
            </div>
            {typeof hdp?.hp === "string" && (
              <div className="flex">
                <Button
                  isDisabled={!hdp?.hp}
                  className="w-16 border-l"
                  priceChangedType={homePriceChangedType}
                  isActive={betTicketInfoPayload?.data?.some(
                    (ticket: any) =>
                      ticket.matchId === `${match.k}` &&
                      ticket.betType === "ah" &&
                      ticket.riskLevel === 0 &&
                      ticket.option === 0
                  )}
                  hdp={`H (${convertHDP(hdp?.h, true)})`}
                  price={convertOdds(Number(hdp?.hp), oddsType.data)}
                  onClick={() => {
                    handle?.({
                      option: 0,
                      riskLevel: 0,
                      betType: "ah",
                      isHomeGood: isGoodTeam(hdp?.h),
                      isAwayGood: isGoodTeam(hdp?.a),
                      outcomeId: hdp.och,
                      specifiers: hdp.sp,
                      marketId: hdp.mkid,
                    });
                  }}
                />
                <Button
                  isDisabled={!hdp?.ap}
                  className="w-16 border-l"
                  priceChangedType={awayPriceChangedType}
                  isActive={betTicketInfoPayload?.data?.some(
                    (ticket: any) =>
                      ticket.matchId === `${match.k}` &&
                      ticket.betType === "ah" &&
                      ticket.riskLevel === 0 &&
                      ticket.option === 1
                  )}
                  hdp={`A (${convertHDP(hdp?.a, true)})`}
                  price={convertOdds(Number(hdp?.ap), oddsType.data)}
                  onClick={() => {
                    handle?.({
                      option: 1,
                      riskLevel: 0,
                      betType: "ah",
                      isHomeGood: isGoodTeam(hdp?.h),
                      isAwayGood: isGoodTeam(hdp?.a),
                      outcomeId: hdp.oca,
                      specifiers: hdp.sp,
                      marketId: hdp.mkid,
                    });
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex justify-between w-full">
            <div className="flex items-center gap-x-2">
              <div className="flex items-center px-2 pt-1 pb-1">
                {isLive ? (
                  <span className="relative flex h-2 w-2 mr-1">
                    <span className="absolute animate-ping w-2 h-2 rounded-full bg-rose-500" />
                    <span className="w-2 h-2 rounded-full bg-rose-500" />
                  </span>
                ) : (
                  <AccessTimeIcon className="mr-1" style={{ fontSize: 16 }} />
                )}
                <span className="text-xs">
                  {isLive
                    ? match.i.lt
                    : isToday
                    ? moment(match.od).format("HH:mm")
                    : moment(match.od).format("ddd DD/MM HH:mm")}
                </span>
              </div>
              {!!match.st && (
                <a href={match.st} target="_blank" rel="noreferrer">
                  <img src="/svg/static.svg" width={16} alt="" />
                </a>
              )}
              {match.isChannel && (
                <a
                  href={`https://askmebet.live/${
                    i18n.language as string
                  }/match/${cleanCornerId(match.k)}`}
                  target="_blank"
                  rel="noreferrer">
                  <img
                    width="23px"
                    height="14px"
                    src={`${process.env.PUBLIC_URL}/live-streaming.webp`}
                    alt=""
                  />
                </a>
              )}
            </div>
            {match?.[betPriceKey]?.length > 0 && (
              <div className="flex">
                <div
                  className="font-bold flex items-center justify-center relative border-l pl-7"
                  style={{ width: 128 }}
                  onClick={() => {
                    onClickMore?.();
                    setSearchParams({
                      leagueId,
                      matchId: match.k,
                      sport,
                      type,
                    });
                    setIsCollapse(!isCollapse);
                  }}>
                  <div className="text-xs mr-2">{t("More")}</div>
                  <Badge className="text-xs">
                    +
                    {Math.abs(
                      new Set(
                        match?.[betPriceKey]?.reduce(
                          (total: Array<string>, current: Record<any, any>) => [
                            ...total,
                            ...Object.keys(current),
                          ],
                          []
                        )
                      ).size + Number(match.msp || 0)
                    )}
                  </Badge>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewMatchTable;
