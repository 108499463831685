import { login } from "./login";
import { getProfile } from "./getProfile";
import { getFavorites } from "./getFavorites";
import { pinFavorite } from "./pinFavorite";

class MemberService {
  public login = login;
  public getProfile = getProfile;
  public getFavorites = getFavorites;
  public pinFavorite = pinFavorite;
}

export default MemberService;
