import { useQuery } from "@tanstack/react-query";
import query from "../../../query";
import ArrowPathIcon from "../../../components/Icons/ArrowPathIcon";
import classNames from "classnames";
import BetPriceTable from "../SportTableMobile/BetPriceTable";
import { SpecialProp } from "./types";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useCountDown } from "../../../hooks/useCountDown";

export default function PriceComponent({
  leagueId,
  matchId,
  tableType,
  homeName,
  awayName,
  leagueName,
  sport,
}: SpecialProp) {
  const { i18n } = useTranslation();
  let sportFeedQuery = query.specialFootballFeed(matchId);
  const sportFeed = useQuery(sportFeedQuery.key, sportFeedQuery.queryFn, {
    refetchInterval: 10000,
  });

  const betTypeQuery = query.betType();
  const betType = useQuery(betTypeQuery.key, betTypeQuery.queryFn, {
    refetchOnMount: false,
    keepPreviousData: true,
  });

  const feed = useMemo(
    () => sportFeed.data,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sportFeed.data]
  );

  const initialCounter = 60;
  const isLoading = sportFeed.isLoading;

  const { resetCounter } = useCountDown({
    initialCounter,
    isLoading,
    onTimeOut: () => {
      resetCounter();
      sportFeed.refetch();
    },
  });

  return (
    <>
      {sportFeed.isLoading && (
        <ArrowPathIcon
          className={classNames("mr-1", {
            "animate-spin": sportFeed.isLoading,
          })}
        />
      )}

      {!sportFeed.isLoading && (
        <>
          <BetPriceTable
            leagueId={`${leagueId}`}
            matchId={`${matchId}`}
            data={feed ?? []}
            sportType={sport.toUpperCase()}
            tableType={tableType}
            homeName={homeName[i18n.language as string].h}
            awayName={awayName[i18n.language as string].a}
            leagueName={leagueName}
            betType={betType.data ?? "normal"}
          />
        </>
      )}
    </>
  );
}
