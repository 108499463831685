export const convertOdds = (odds: number, oddsType = 0) => {
  odds = Number(odds);

  if (Number.isNaN(odds)) {
    return "";
  }

  switch (oddsType) {
    case 0: // malay
      return odds.toFixed(2);

    case 1: //hongkong
      if (odds > 0) {
        return odds.toFixed(2);
      }

      return (1 / Math.abs(odds)).toFixed(2);

    case 2: // eu

      if(odds > 1) {
        return odds.toFixed(2);
      }
      else if (odds > 0) {
        return (odds + 1).toFixed(2);
      }

      const result = 100 / (Math.abs(odds) * 100) + 1;

      return result.toFixed(2);

    case 3:
      if (odds > 0 && odds <= 1) {
        return (-1 + (-1 + odds)).toFixed(2);
      } else if (odds < 0) {
        return (1 + (1 + odds)).toFixed(2);
      }

      return `0.00`;

    default:
      return odds.toFixed(2);
  }
};
