import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StyledDivWithBg } from "./styled";
import { TeamName } from "../../components/Typography";
import BetPriceTable from "../../contrainers/SportTable/SportTableMobile/BetPriceTable";
import query from "../../query";
import { isGoodTeam } from "../../utils/isGoodTeam";
import { cleanCornerId } from "../../utils/cleanConnerId";
import Loading from "../../components/Icons/Loading";
import PriceComponent from "../../contrainers/SportTable/Special/price";

type MatchMarketProps = {
  onClose?: () => void;
};

const MatchMarket: FC<MatchMarketProps> = ({ onClose }) => {
  const [searchParams] = useSearchParams();

  const {
    sport = searchParams.get("sport") || "football",
    type = searchParams.get("type") || "today",
    leagueId = searchParams.get("leagueId"),
    matchId = searchParams.get("matchId"),
  } = useParams<{
    sport:
      | "football"
      | "basketball"
      | "volleyball"
      | "tabletennis"
      | "handball"
      | "icehockey"
      | "tennis"
      | "snooker"
      | "baseball"
      | "rugby"
      | "americanFootball"
      | "badminton";
    type: "today" | "live" | "early";
    leagueId: string;
    matchId: string;
  }>();

  const { i18n } = useTranslation();
  const betTypeQuery = query.betType();
  const betType = useQuery(betTypeQuery.key, betTypeQuery.queryFn, {
    refetchOnMount: false,
    keepPreviousData: true,
  });

  let sportFeedQuery;

  switch (sport) {
    case "badminton": {
      sportFeedQuery = query.badmintonFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "americanFootball": {
      sportFeedQuery = query.americanfootballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "rugby": {
      sportFeedQuery = query.rugbyFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "baseball": {
      sportFeedQuery = query.baseballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "snooker": {
      sportFeedQuery = query.snookerFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "tennis": {
      sportFeedQuery = query.tennisFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "icehockey": {
      sportFeedQuery = query.icehockeyFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "handball": {
      sportFeedQuery = query.handballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "tabletennis": {
      sportFeedQuery = query.tabletennisFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "basketball": {
      sportFeedQuery = query.basketballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    case "volleyball": {
      sportFeedQuery = query.volleyballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
    }

    default:
      sportFeedQuery = query.footballFeed(
        type as "today" | "live" | "early",
        betType.data ?? "normal"
      );

      break;
  }

  let betPriceKey: "bpl" | "bp" = type === "live" ? "bpl" : "bp";

  const sportFeed = useQuery(sportFeedQuery.key, sportFeedQuery.queryFn, {
    refetchInterval: type === "live" ? 10 * 1000 : 30 * 1000,
  });

  const foundLeague =
    leagueId &&
    matchId &&
    sportFeed.data?.find((league) => {
      return `${league.k}` === `${leagueId}`;
    });

  const foundMatch =
    foundLeague &&
    foundLeague?.m?.find((match) => {
      return `${match.k}` === `${matchId}`;
    });

  if (sportFeed.isLoading) {
    return <Loading className="m-auto" />;
  } else if (!foundMatch) {
    return <div className="flex text-center">Not found</div>;
  }

  const betPrice = foundMatch ? foundMatch?.[betPriceKey] : [];

  return (
    <div className="bg-white mb-10 flex flex-col h-full">
      <StyledDivWithBg sport={sport} className="relative">
        <div
          className="h-full w-full py-2"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            minHeight: 100,
          }}>
          <div className="flex items-center justify-between px-2 mb-5">
            <button className="text-white truncate" onClick={onClose}>
              <ArrowBackIosNewIcon
                className="align-text-bottom"
                style={{ fontSize: 18 }}
              />
              {foundLeague?.nn[i18n.language as string]}
            </button>
            <div className="flex">
              {!!foundMatch.st && (
                <a
                  href={foundMatch.st}
                  target="_blank"
                  rel="noreferrer"
                  className="p-1 flex items-center justify-center w-8 h-8">
                  <SignalCellularAltIcon className="text-white" />
                </a>
              )}
              {foundMatch.isChannel && (
                <a
                  href={`https://askmebet.live/${
                    i18n.language as string
                  }/match/${cleanCornerId(foundMatch.k)}`}
                  target="_blank"
                  rel="noreferrer"
                  className="p-1 flex items-center justify-center w-8 h-8">
                  <img
                    width="23px"
                    height="14px"
                    src={`${process.env.PUBLIC_URL}/live-streaming.webp`}
                    alt=""
                  />
                </a>
              )}
            </div>
          </div>

          <div className="flex">
            <div className="flex flex-col flex-1 justify-start">
              {!!foundMatch.n?.ngidh && (
                <img
                  src={`https://images.askmebet-odds.com/teams/${foundMatch.n?.ngidh}.png?size=30`}
                  alt=""
                  className="w-10 h-10 mx-auto mb-2 rounded-full"
                />
              )}

              <TeamName
                className="text-center text-sm font-bold px-2"
                isGoodTeam={isGoodTeam(foundMatch?.[betPriceKey]?.[0]?.ah?.h)}
                color="white">
                {foundMatch?.n[i18n.language as string].h}
                {!!foundMatch?.i?.hrc &&
                  foundMatch?.i?.hrc !== "NaN" &&
                  foundMatch?.i?.hrc !== "0" && (
                    <span className="ml-2">
                      {Array.from({
                        length: Number(foundMatch?.i?.hrc),
                      }).map(() => (
                        <img
                          className="inline"
                          src="/red.png"
                          alt="red-card"
                          width={20}
                        />
                      ))}
                    </span>
                  )}
              </TeamName>
            </div>

            <div className="">
              {foundMatch.i.lt !== "Live" ? (
                <div className="flex text-white">
                  <div className="flex-1 text-right font-bold">
                    {foundMatch.i.h}
                  </div>
                  <div className="font-bold px-3">-</div>
                  <div className="flex-1 text-left font-bold">
                    {foundMatch.i.a}
                  </div>
                </div>
              ) : (
                <div className="font-bold text-sm text-center text-white px-3">
                  VS
                </div>
              )}
              <div className="text-md flex justify-center items-center text-white mb-2">
                <div className="flex items-center relative">
                  {foundMatch.i.lt !== "Live" ? (
                    <span className="relative flex h-2 w-2 mr-1">
                      <span className="absolute animate-ping w-2 h-2 rounded-full bg-rose-500" />
                      <span className="w-2 h-2 rounded-full bg-rose-500" />
                    </span>
                  ) : (
                    <AccessTimeIcon
                      className="absolute top-1.5"
                      style={{ fontSize: 13, left: -16 }}
                    />
                  )}
                  {foundMatch.i.lt !== "Live"
                    ? foundMatch.i.lt
                    : moment()
                        .utc()
                        .startOf("day")
                        .isSame(moment(foundMatch.od), "day")
                    ? moment(foundMatch.od).format("HH:mm")
                    : moment(foundMatch.od).format("ddd DD/MM HH:mm")}
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-1 justify-start">
              {!!foundMatch.n?.ngida && (
                <img
                  src={`https://images.askmebet-odds.com/teams/${foundMatch.n?.ngida}.png?size=30`}
                  alt=""
                  className="w-10 h-10 mx-auto mb-2 rounded-full"
                />
              )}
              <TeamName
                className="text-center text-sm font-bold px-2"
                isGoodTeam={isGoodTeam(foundMatch?.[betPriceKey]?.[0]?.ah?.a)}
                color="white">
                {foundMatch?.n[i18n.language as string].a}
                {!!foundMatch?.i?.arc &&
                  foundMatch?.i?.arc !== "NaN" &&
                  foundMatch?.i?.arc !== "0" && (
                    <span className="ml-2">
                      {Array.from({
                        length: Number(foundMatch?.i?.arc),
                      }).map(() => (
                        <img
                          className="inline"
                          src="/red.png"
                          alt="red-card"
                          width={20}
                        />
                      ))}
                    </span>
                  )}
              </TeamName>
            </div>
          </div>
        </div>
      </StyledDivWithBg>
      <div className="overflow-y-auto flex-1">
        <BetPriceTable
          leagueId={leagueId}
          matchId={matchId}
          data={betPrice ?? []}
          sportType={sport.toUpperCase()}
          tableType={type}
          homeName={foundMatch?.n[i18n.language as string].h}
          awayName={foundMatch?.n[i18n.language as string].a}
          leagueName={foundLeague?.nn[i18n.language as string]}
          betType={betType.data ?? "normal"}
          faid={foundMatch.n.faid}
          fhid={foundMatch.n.fhid}
        />
        <PriceComponent
          leagueId={+leagueId}
          matchId={+matchId}
          sportType={sport.toUpperCase()}
          tableType={type}
          homeName={foundMatch?.n}
          awayName={foundMatch?.n}
          leagueName={foundLeague?.nn[i18n.language as string]}
          sport={sport}
          priceCount={foundMatch.msp}
        />
      </div>
    </div>
  );
};

export default MatchMarket;
