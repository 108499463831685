import { Row } from "@tanstack/react-table";
import { FormattedFeed } from "../../types/feed";
import { StarIcon } from "@heroicons/react/24/outline";
import { StarIcon as StarSolidIcon } from "@heroicons/react/24/solid";

export const renderSubComponent = ({
  row,
  onClick,
}: {
  row: Row<FormattedFeed>;
  onClick?: Function;
}) => {
  if (row.original.feed_order !== 0 || row.original.betPrice_order !== 0) {
    return <></>;
  }

  return (
    <tr>
      <th colSpan={10} className="pl-2 py-1 text-left league">
        <button
          className="align-text-bottom mr-1"
          onClick={() => {
            onClick?.(
              { id: `${row.original.feed_k}`, name: row.original.feed_nn },
              "LEAGUE"
            );
          }}
        >
          {Boolean(row.original.feed_isFavorite) ? (
            <StarSolidIcon className="w-5 h-5 text-yellow-400" />
          ) : (
            <StarIcon className="w-5 h-5 text-yellow-400" />
          )}
        </button>
        <b>{row.original.feed_nn}</b>
        <span className="ml-4 text-xs text-gray-600 rounded bg-white px-1">
          {row.original.feed_ms}
        </span>
      </th>
    </tr>
  );
};
