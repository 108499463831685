import { FC } from "react";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Wrapper } from "./styled";
import query from "../../query";
import { Announcement } from "../../types/announcement";
import MegaphoneIcon from "../../components/Icons/MegaphoneIcon";
import classNames from "classnames";
import useScreen from "../../hooks/useScreen";

const filter = {
  page: 1,
  limit: 20,
  startDate: moment().startOf("day").format("DD-MM-YYYY"),
  endDate: moment().endOf("day").format("DD-MM-YYYY"),
};

const AnnouncementBar: FC = () => {
  const { t, i18n } = useTranslation();
  const { isDesktop } = useScreen();

  const announcementQuery = query.announcement(filter);
  const announcement = useQuery(
    announcementQuery.key,
    announcementQuery.queryFn
  );

  return (
    <Wrapper>
      <div className="flex tablet:px-8 mobile:py-0 mobile:px-1">
        <div className={classNames("flex", { "pr-2": isDesktop })}>
          {isDesktop && (
            <Link
              className="mr-1 min-w-fit flex items-center"
              to="/announcement"
            >
              {t("All Announcement")}
            </Link>
          )}
          <MegaphoneIcon style={{ fontSize: 33 }} />
        </div>
        {!announcement?.isLoading && (
          <Marquee gradient={false} pauseOnHover speed={50} delay={2}>
            {(announcement?.data as any)?.docs?.map((doc: Announcement) => (
              <p className="ml-40" key={doc._id}>
                {doc.msg[i18n.language as "th" | "en"]}
              </p>
            ))}
          </Marquee>
        )}
      </div>
    </Wrapper>
  );
};

export default AnnouncementBar;
