import { FC, MouseEvent, HTMLAttributes } from "react";
import ArrowPathIcon from "../../components/Icons/ArrowPathIcon";
import { useCountDown } from "../../hooks/useCountDown";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export type RefreshProps = {
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  initialCounter: number;
  isLoading?: boolean;
  noLabel?: boolean;
};

const Refresh: FC<RefreshProps & HTMLAttributes<HTMLButtonElement>> = ({
  onClick = () => {},
  initialCounter,
  isLoading = false,
  noLabel = false,
  style,
  className,
}) => {
  const { t } = useTranslation();
  const { counter, resetCounter } = useCountDown({
    initialCounter,
    isLoading,
    onTimeOut: () => {
      resetCounter();
      onClick();
    },
  });

  return (
    <button
      onClick={(event) => {
        resetCounter();
        onClick(event);
      }}
      style={style}
      className={classNames(["flex items-center text-sm p-1", className])}
      type="button"
      disabled={isLoading}
    >
      <ArrowPathIcon
        className={classNames("mr-1", { "animate-spin": isLoading })}
      />
      {!noLabel &&
        (isLoading ? `${t("Refreshing")}...` : `${t("Refresh")} [${counter}]`)}
      {noLabel && counter}
    </button>
  );
};

export default Refresh;
