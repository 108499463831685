import { BetPrice } from "../../../../types/feed";

export type MapHeaderValue = {
  title: string;
  subHeader: string[];
  isShownTeamName?: boolean;
};

export type MapHeader = Record<string, MapHeaderValue>;

export type FormatOptions = {
  homeName?: string;
  awayName?: string;
};

export const mapHeader: MapHeader = {
  ah: {
    title: "Asian Handicap",
    subHeader: ["Home", "Away"],
    isShownTeamName: true,
  },
  ou: { title: "Total Goal Over / Under", subHeader: ["Over", "Under"] },
  x12: { title: "1X2", subHeader: [] },
  dc: { title: "Double Chance", subHeader: [] },
  ml: { title: "Money Line", subHeader: [] },
  oe: { title: "Total Goal Odd / Even", subHeader: [] },
  ah1st: {
    title: "1st Half Asian Handicap",
    subHeader: ["Home", "Away"],
    isShownTeamName: true,
  },
  ou1st: { title: "1st Half Total Goal", subHeader: ["Over", "Under"] },
  x121st: { title: "1st Half 1X2", subHeader: [] },
  ml1st: { title: "1st Half Money Line", subHeader: [] },
  yn: { title: "Both Teams to Score", subHeader: [] },
  hwn: { title: " Home Win to Nil", subHeader: [] },
  awn: { title: " Away Win to Nil", subHeader: [] },
  hto: { title: " Home Team Total Goal", subHeader: ["Over", "Under"] },
  ato: { title: " Away Team Total Goal", subHeader: ["Over", "Under"] },
  tob1st: { title: " 1st Total bookings", subHeader: ["Over", "Under"] },
  tob: { title: " Total bookings", subHeader: ["Over", "Under"] },
  "ht/ft": { title: "Half Time / Full Time", subHeader: [] },
  cr: { title: "Correct Score", subHeader: [] },
};

export const changeSubHeader = (
  header: MapHeaderValue,
  homeName: string,
  awayName: string
) => {
  return {
    ...header,
    subHeader: [homeName, awayName],
  };
};

export const format = (data: any, options?: FormatOptions) => {
  return data.reduce(
    (result: any, bp: BetPrice, index: number) => {
      return {
        ah1st: bp?.ah1st
          ? [
              ...result.ah1st,
              [
                {
                  label: "",
                  riskLevel: index,
                  price: bp.ah1st.hp,
                  hdp: bp.ah1st.h,
                  marketId: bp.ah1st.mkid,
                  sp: bp.ah1st.sp,
                  outcomeId: bp.ah1st.och,
                  option: 0,
                },
                {
                  label: "",
                  riskLevel: index,
                  price: bp.ah1st.ap,
                  hdp: bp.ah1st.a,
                  marketId: bp.ah1st.mkid,
                  sp: bp.ah1st.sp,
                  outcomeId: bp.ah1st.oca,
                  option: 1,
                },
              ],
            ]
          : result.ah1st || [],
        ou1st: bp?.ou1st
          ? [
              ...result.ou1st,
              [
                {
                  label: "",
                  riskLevel: index,
                  price: bp.ou1st.op,
                  hdp: bp.ou1st.o,
                  marketId: bp.ou1st.mkid,
                  sp: bp.ou1st.sp,
                  outcomeId: bp.ou1st.oco,
                  option: 0,
                },
                {
                  label: "",
                  riskLevel: index,
                  price: bp.ou1st.up,
                  hdp: bp.ou1st.u,
                  marketId: bp.ou1st.mkid,
                  sp: bp.ou1st.sp,
                  outcomeId: bp.ou1st.ocu,
                  option: 1,
                },
              ],
            ]
          : result.ou1st || [],
        x121st: bp?.x121st
          ? [
              [
                {
                  label: options?.homeName || "Home win",
                  riskLevel: index,
                  price: bp.x121st.h,
                  hdp: "",
                  marketId: bp.x121st.mkid,
                  sp: bp.x121st.sp,
                  outcomeId: bp.x121st.och,
                  option: 0,
                },
                {
                  label: "Draw",
                  riskLevel: index,
                  price: bp.x121st.d,
                  hdp: "",
                  marketId: bp.x121st.mkid,
                  sp: bp.x121st.sp,
                  outcomeId: bp.x121st.ocd,
                  option: 2,
                },
                {
                  label: options?.awayName || "Away win",
                  riskLevel: index,
                  price: bp.x121st.a,
                  hdp: "",
                  marketId: bp.x121st.mkid,
                  sp: bp.x121st.sp,
                  outcomeId: bp.x121st.oca,
                  option: 1,
                },
              ],
            ]
          : result.x121st || [],
        ml1st: bp?.ml1st
          ? [
              [
                {
                  label: options?.homeName || "Home win",
                  riskLevel: index,
                  price: bp.ml1st?.h,
                  hdp: "",
                  marketId: bp.ml1st?.mkid,
                  sp: bp.ml1st?.sp,
                  outcomeId: bp.ml1st?.och,
                  option: 0,
                },
                {
                  label: options?.awayName || "Away win",
                  riskLevel: index,
                  price: bp.ml1st?.a,
                  hdp: "",
                  marketId: bp.ml1st?.mkid,
                  sp: bp.ml1st?.sp,
                  outcomeId: bp.ml1st?.oca,
                  option: 1,
                },
              ],
            ]
          : result.ml1st || [],
        tob1st: bp?.tob1st
          ? [
              ...result.tob1st,
              [
                {
                  label: "",
                  riskLevel: index,
                  price: bp.tob1st.op,
                  hdp: bp.tob1st.o,
                  marketId: bp.tob1st.mkid,
                  sp: bp.tob1st.sp,
                  outcomeId: bp.tob1st.oco,
                  option: 0,
                },
                {
                  label: "",
                  riskLevel: index,
                  price: bp.tob1st.up,
                  hdp: bp.tob1st.u,
                  marketId: bp.tob1st.mkid,
                  sp: bp.tob1st.sp,
                  outcomeId: bp.tob1st.ocu,
                  option: 1,
                },
              ],
            ]
          : result.tob1st || [],

        ah: bp?.ah
          ? [
              ...result.ah,
              [
                {
                  label: "",
                  riskLevel: index,
                  price: bp.ah.hp,
                  hdp: bp.ah.h,
                  marketId: bp.ah.mkid,
                  sp: bp.ah.sp,
                  outcomeId: bp.ah.och,
                  option: 0,
                },
                {
                  label: "",
                  riskLevel: index,
                  price: bp.ah.ap,
                  hdp: bp.ah.a,
                  marketId: bp.ah.mkid,
                  sp: bp.ah.sp,
                  outcomeId: bp.ah.oca,
                  option: 1,
                },
              ],
            ]
          : result?.ah || [],
        ou: bp?.ou
          ? [
              ...result.ou,
              [
                {
                  label: "",
                  riskLevel: index,
                  price: bp.ou.op,
                  hdp: bp.ou.o,
                  marketId: bp.ou.mkid,
                  sp: bp.ou.sp,
                  outcomeId: bp.ou.oco,
                  option: 0,
                },
                {
                  label: "",
                  riskLevel: index,
                  price: bp.ou.up,
                  hdp: bp.ou.u,
                  marketId: bp.ou.mkid,
                  sp: bp.ou.sp,
                  outcomeId: bp.ou.ocu,
                  option: 1,
                },
              ],
            ]
          : result?.ou || [],
        x12: bp?.x12
          ? [
              [
                {
                  label: options?.homeName || "Home win",
                  riskLevel: index,
                  price: bp.x12.h,
                  hdp: "",
                  marketId: bp.x12.mkid,
                  sp: bp.x12.sp,
                  outcomeId: bp.x12.och,
                  option: 0,
                },
                {
                  label: "Draw",
                  riskLevel: index,
                  price: bp.x12.d,
                  hdp: "",
                  marketId: bp.x12.mkid,
                  sp: bp.x12.sp,
                  outcomeId: bp.x12.ocd,
                  option: 2,
                },
                {
                  label: options?.awayName || "Away win",
                  riskLevel: index,
                  price: bp.x12.a,
                  hdp: "",
                  marketId: bp.x12.mkid,
                  sp: bp.x12.sp,
                  outcomeId: bp.x12.oca,
                  option: 1,
                },
              ],
            ]
          : result.x12 || [],
        ml: bp?.ml
          ? [
              [
                {
                  label: options?.homeName || "Home win",
                  riskLevel: index,
                  price: bp.ml?.h,
                  hdp: "",
                  marketId: bp.ml?.mkid,
                  sp: bp.ml?.sp,
                  outcomeId: bp.ml?.och,
                  option: 0,
                },
                {
                  label: options?.awayName || "Away win",
                  riskLevel: index,
                  price: bp.ml?.a,
                  hdp: "",
                  marketId: bp.ml?.mkid,
                  sp: bp.ml?.sp,
                  outcomeId: bp.ml?.oca,
                  option: 1,
                },
              ],
            ]
          : result.ml || [],
        tob: bp?.tob
          ? [
              ...result.tob,
              [
                {
                  label: "",
                  riskLevel: index,
                  price: bp.tob.op,
                  hdp: bp.tob.o,
                  marketId: bp.tob.mkid,
                  sp: bp.tob.sp,
                  outcomeId: bp.tob.oco,
                  option: 0,
                },
                {
                  label: "",
                  riskLevel: index,
                  price: bp.tob.up,
                  hdp: bp.tob.u,
                  marketId: bp.tob.mkid,
                  sp: bp.tob.sp,
                  outcomeId: bp.tob.ocu,
                  option: 1,
                },
              ],
            ]
          : result.tob || [],
        oe: bp?.oe
          ? [
              [
                {
                  label: "Odd",
                  riskLevel: index,
                  price: bp.oe.o,
                  hdp: "",
                  marketId: bp.oe.mkid,
                  sp: bp.ml?.sp,
                  outcomeId: bp.oe.oco,
                  option: 0,
                },
                {
                  label: "Even",
                  riskLevel: index,
                  price: bp.oe.e,
                  hdp: "",
                  marketId: bp.oe.mkid,
                  sp: bp.oe?.sp,
                  outcomeId: bp.oe.oce,
                  option: 1,
                },
              ],
            ]
          : result.oe || [],
        dc: bp?.dc
          ? [
              [
                {
                  label: "1X",
                  riskLevel: index,
                  price: bp.dc?.["1x"],
                  hdp: "",
                  marketId: bp.dc.mkid,
                  sp: null,
                  outcomeId: bp.dc.och,
                  option: 0,
                },
                {
                  label: "12",
                  riskLevel: index,
                  price: bp.dc?.["12"],
                  hdp: "",
                  marketId: bp.dc.mkid,
                  sp: null,
                  outcomeId: bp.dc.ocd,
                  option: 2,
                },
                {
                  label: "X2",
                  riskLevel: index,
                  price: bp.dc?.["x2"],
                  hdp: "",
                  marketId: bp.dc.mkid,
                  sp: null,
                  outcomeId: bp.dc.oca,
                  option: 1,
                },
              ],
            ]
          : result.dc || [],
        yn: bp?.yn
          ? [
              [
                {
                  label: "Yes",
                  riskLevel: index,
                  price: bp.yn?.y,
                  hdp: "",
                  marketId: bp.yn?.mkid,
                  sp: "",
                  outcomeId: bp.yn?.yid,
                  option: 0,
                },
                {
                  label: "No",
                  riskLevel: index,
                  price: bp.yn?.n,
                  hdp: "",
                  marketId: bp.yn?.mkid,
                  sp: "",
                  outcomeId: bp.yn?.nid,
                  option: 1,
                },
              ],
            ]
          : result.yn || [],
        hwn: bp?.hwn
          ? [
              [
                {
                  label: "Yes",
                  riskLevel: index,
                  price: bp.hwn?.y,
                  hdp: "",
                  marketId: bp.hwn?.mkid,
                  sp: "",
                  outcomeId: bp.hwn?.yid,
                  option: 0,
                },
                {
                  label: "No",
                  riskLevel: index,
                  price: bp.hwn?.n,
                  hdp: "",
                  marketId: bp.hwn?.mkid,
                  sp: "",
                  outcomeId: bp.hwn?.nid,
                  option: 1,
                },
              ],
            ]
          : result.hwn || [],
        awn: bp?.awn
          ? [
              [
                {
                  label: "Yes",
                  riskLevel: index,
                  price: bp.awn?.y,
                  hdp: "",
                  marketId: bp.awn?.mkid,
                  sp: "",
                  outcomeId: bp.awn?.yid,
                  option: 0,
                },
                {
                  label: "No",
                  riskLevel: index,
                  price: bp.awn?.n,
                  hdp: "",
                  marketId: bp.awn?.mkid,
                  sp: "",
                  outcomeId: bp.awn?.nid,
                  option: 1,
                },
              ],
            ]
          : result.awn || [],

        hto: bp?.hto
          ? [
              ...(result?.hto || []),
              [
                {
                  label: "",
                  riskLevel: index,
                  price: bp.hto.op,
                  hdp: bp.hto.o,
                  marketId: bp.hto.mkid,
                  sp: bp.hto.sp,
                  outcomeId: bp.hto.oco,
                  option: 0,
                },
                {
                  label: "",
                  riskLevel: index,
                  price: bp.hto.up,
                  hdp: bp.hto.u,
                  marketId: bp.hto.mkid,
                  sp: bp.hto.sp,
                  outcomeId: bp.hto.ocu,
                  option: 1,
                },
              ],
            ]
          : result.hto || [],
        ato: bp?.ato
          ? [
              ...(result?.ato || []),
              [
                {
                  label: "",
                  riskLevel: index,
                  price: bp.ato.op,
                  hdp: bp.ato.o,
                  marketId: bp.ato.mkid,
                  sp: bp.ato.sp,
                  outcomeId: bp.ato.oco,
                  option: 0,
                },
                {
                  label: "",
                  riskLevel: index,
                  price: bp.ato.up,
                  hdp: bp.ato.u,
                  marketId: bp.ato.mkid,
                  sp: bp.ato.sp,
                  outcomeId: bp.ato.ocu,
                  option: 1,
                },
              ],
            ]
          : result.ato || [],
        "ht/ft": bp?.["ht/ft"]
          ? bp?.["ht/ft"]?.d.reduce(
              (total: any, d: any, i: number) => {
                const option = {
                  label: d.n,
                  riskLevel: index,
                  price: d.p,
                  hdp: "",
                  marketId: bp?.["ht/ft"]?.mkid,
                  sp: "",
                  outcomeId: d.ocid,
                  option: i,
                };
                if ((i + 1) % 2 === 0) {
                  total[total.length - 1].push(option);
                } else {
                  total.push([option]);
                }
                return total;
              },
              [...(result?.["ht/ft"] || [])]
            )
          : result?.["ht/ft"] || [],
        cr: bp?.cr
          ? bp?.cr?.d.reduce(
              (total: any, d: any, i: number) => {
                const option = {
                  label: d.s === "AOS" ? "Any Other" : d.s,
                  riskLevel: index,
                  price: d.p,
                  hdp: "",
                  marketId: bp?.cr?.mkid,
                  sp: "",
                  outcomeId: d.ocid,
                  option: i,
                };
                if ((i + 1) % 2 === 0) {
                  total[total.length - 1].push(option);
                } else {
                  total.push([option]);
                }
                return total;
              },
              [...(result?.cr || [])]
            )
          : result?.cr || [],
      };
    },
    {
      ah1st: [],
      ou1st: [],
      x121st: [],
      ml1st: [],
      tob1st: [],
      ah: [],
      ou: [],
      x12: [],
      ml: [],
      tob: [],
      oe: [],
      dc: [],
      yn: [],
      hwn: [],
      awn: [],
      hto: [],
      ato: [],
      "ht/ft": [],
      cr: [],
    }
  );
};
