import { Device } from "../types/device";
import useDevice from "./useDevice";
import useIsForceScreen from "./useIsForceScreen";
import { useState } from "react";

const useScreen = () => {
  const device = useDevice();

  let isDesktop = device === Device.DESKTOP;
  const [isDesktopDefault] = useState(isDesktop);

  const { isForceScreen, forceSwitchScreen } = useIsForceScreen();
  // console.log("isDesktop",isDesktop,isForceScreen)
  // const temp = isDesktopDefault === isDesktop ? isDesktop : isDesktopDefault;

    isDesktop = isForceScreen && !isDesktop ? !isDesktop : isDesktop; // isForceScreen will make screen switch mobile => desktop and desktop => mobile
 
 

  return {
    isDesktopDefault,
    forceSwitchScreen,
    isDesktop,
    isForceScreen,
  };
};

export default useScreen;
