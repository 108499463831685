import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestHeaders } from "axios";
import { PropsWithChildren, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Loading from "../../components/Loading";
import query from "../../query";

const ProfileProvider = ({ children }: PropsWithChildren<{}>) => {
  const profile = query.profile();
  const [searchParams, setSearchParams] = useSearchParams();
  let token;
  try {
    token = window?.localStorage.getItem("auth");
  } catch (error) {
    console.error("Cannot access localStorage:", error);
  }

  const hasToken = Boolean(token && token !== "undefined");
  const uuid = searchParams.get("uuid");
  const hasUuid = Boolean(uuid);
  const login = query.login(uuid as string);

  useEffect(() => {
    const iframe = searchParams.get("iframe");
    const isIframe = iframe === "true";
    window?.localStorage.setItem("isIframe", `${isIframe}`);

    const lang = searchParams.get("lang");
    window?.localStorage.setItem("i18nextLng", `${lang}`);

    const desktop = searchParams.get("desktop");
    const isForceDesktop = desktop === "true";
    window?.localStorage.setItem("isForceDesktop", `${isForceDesktop}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading: isLoginLoading } = useQuery(login.key, login.queryFn, {
    enabled: hasUuid,
    onSuccess: (token: string) => {
      if (token) {
        window?.localStorage.setItem("auth", token);
        setSearchParams(``);

        axios.interceptors.request.use((config) => {
          if (token && token !== "undefined") {
            (config.headers as AxiosRequestHeaders).Authorization = token;
          }

          return config;
        });
      }
    },
    onError: (_error) => {
      window?.localStorage.removeItem("auth");
    },
  });

  const { data: profileData, isLoading: isProfileLoading } = useQuery(
    profile.key,
    profile.queryFn,
    {
      enabled: (hasToken && !isLoginLoading) || (hasToken && !hasUuid),
      onError: () => {
        window?.localStorage.removeItem("auth");
      },
    }
  );

  if ((isProfileLoading && hasToken) || (isLoginLoading && hasUuid)) {
    return <Loading className="m-auto" />;
  }

  if (!profileData) {
    return <div className="m-auto">Unauthorized</div>;
  }

  return <>{children}</>;
};

export default ProfileProvider;
