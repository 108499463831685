import { FC } from "react";
import { OddPrice } from "../../../../components/Typography";
import { convertHDP } from "../../../../utils/convertHDP";
import { convertOdds } from "../../../../utils/convertOdds";
import { useChangeDetector } from "../../../../hooks/useOddChangeDetector/useChangeDetector";
import classNames from "classnames";

type MarketButtonProps = {
  data: any;
  isActive?: boolean;
  onClick?: () => void;
  className?: string;
  isDisplayPlus?: boolean;
  oddsType?: number;
};

const MarketButton: FC<MarketButtonProps> = ({
  data,
  onClick = () => {},
  className,
  isActive,
  isDisplayPlus = true,
  oddsType = 0,
}) => {
  const { priceChangedType } = useChangeDetector(data.price, data.hdp);

  return (
    <button
      onClick={onClick}
      className={classNames(
        "p-0.5 flex flex-col flex-1 items-center border rounded-lg",
        className,
        {
          "bg-yellow-300": isActive,
        }
      )}
    >
      {!!data.label && (
        <p
          className="text-xs truncate"
          style={{
            color: "rgb(47, 128, 237)",
          }}
        >
          {data.label}
        </p>
      )}
      {!!data.hdp && (
        <div
          className="text-xs"
          style={{
            color: "rgb(47, 128, 237)",
          }}
        >
          {convertHDP(data.hdp, isDisplayPlus)}
        </div>
      )}
      <div className="text-sm relative">
        <OddPrice
          isDecrease={priceChangedType === "decrease"}
          isIncrease={priceChangedType === "increase"}
          isRedOdd={convertOdds(data.price, oddsType).includes("-")}
        >
          {convertOdds(data.price, oddsType)}
        </OddPrice>
      </div>
    </button>
  );
};

export default MarketButton;
