import { useTranslation } from "react-i18next";
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  Row,
} from "@tanstack/react-table";
import { Fragment, ReactElement } from "react";
import { getEvenClass } from "./getEvenClass";
import { CustomCellContext } from "../../types/table";
import Loading from "../../components/Icons/Loading";
import { BetType } from "../../types/common";
import { typedMemo } from "../../utils/typedMemo";
import React from "react";

export type TableProps<TData> = {
  data?: TData[];
  oddsType?: number;
  betType?: BetType;
  isLoading?: boolean;
  columns: ColumnDef<TData>[];
  placeholder?: string;
  renderSubComponent?: (props: {
    row: Row<TData>;
    onClick?: Function;
  }) => React.ReactElement;
  specialPriceComponent?: (props: { row: Row<TData> }) => React.ReactNode;
  pinFavorite?: (
    payload: { id: string; name: string },
    type: "LEAGUE" | "TEAM"
  ) => void;
} & CustomCellContext;

function Table<T>({
  isLoading = false,
  data = [],
  columns,
  oddsType = 0,
  betType = "normal",
  renderSubComponent,
  specialPriceComponent,
  pinFavorite,
  placeholder,
  onClickOddPrice,
  onClickDate,
  Footer,
  limit,
  page,
}: TableProps<T> & { Footer?: ReactElement }) {
  const { t, i18n } = useTranslation();
  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const rows = table.getRowModel().rows;
  const headerGroups = table.getHeaderGroups();

  const mergeHeaders = headerGroups[0].headers
    .filter((header) => header.isPlaceholder)
    .map((header) => header.index);

  return (
    <table className="w-full">
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, subIndex) => {
              if (index === 1 && mergeHeaders.includes(subIndex)) {
                return <React.Fragment key={header.id} />;
              }

              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  rowSpan={mergeHeaders.includes(subIndex) ? 2 : 1}
                >
                  <div>
                    {flexRender(
                      typeof header.column.columnDef.header === "string"
                        ? t(header.column.columnDef.header)
                        : header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody className="align-top">
        {isLoading && (
          <tr>
            <td colSpan={10} className="text-center text-lg">
              <Loading size={30} className="m-auto block loading" />
            </td>
          </tr>
        )}

        {!rows?.length && !isLoading && (
          <tr>
            <td colSpan={10} className="text-center">
              {placeholder || t("No competition")}
            </td>
          </tr>
        )}

        {!isLoading &&
          rows.map((row, index) => {
            return (
              <Fragment key={row.id}>
                {renderSubComponent?.({
                  row,
                  onClick: pinFavorite,
                })}
                <tr
                  className={getEvenClass(
                    (row.original as any)?.feed_order + 1 || index + 1
                  )}
                >
                  {/* first row is a normal row */}
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, {
                          ...cell.getContext(),
                          oddsType,
                          betType,
                          onClickOddPrice,
                          onClickDate,
                          language: i18n.language,
                          page,
                          limit,
                        })}
                      </td>
                    );
                  })}
                </tr>
                {specialPriceComponent?.({ row })}
              </Fragment>
            );
          })}
      </tbody>
      {Boolean(Footer) && (
        <tfoot>
          {Footer ||
            table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
        </tfoot>
      )}
    </table>
  );
}

export default typedMemo(Table);
