import { FC } from "react";
import capitalize from "lodash/capitalize";
import SportTable from "../../contrainers/SportTable";
import SportTableMobile from "../../contrainers/SportTable/SportTableMobile";
import BetTypeSelectorMobile from "../../contrainers/BetTypeSelector/BetTypeSelectorMobile";
import useScreen from "../../hooks/useScreen";

export type SportTableLiveAndTodayProps = {
  liveTitle: string;
  todayTitle: string;
  sport:
    | "football"
    | "basketball"
    | "volleyball"
    | "tabletennis"
    | "handball"
    | "icehockey"
    | "tennis"
    | "snooker"
    | "baseball"
    | "rugby"
    | "cricket"
    | "americanfootball"
    | "badminton";
};

export type SportTableMobileProps = {
  isBetTypeSelectorDisabled?: boolean;
};

const SportTableLiveAndToday: FC<
  SportTableLiveAndTodayProps & SportTableMobileProps
> = ({ liveTitle, todayTitle, sport, isBetTypeSelectorDisabled = false }) => {
  const { isDesktop } = useScreen();

  if (isDesktop) {
    return (
      <>
        <div className="mb-10 desktop:ml-3 desktop:mr-3">
          <SportTable title={liveTitle} type="live" sport={sport} />
        </div>
        <div className="mb-10 desktop:ml-3 desktop:mr-3">
          <SportTable title={todayTitle} type="today" sport={sport} />
        </div>
      </>
    );
  }

  return (
    <BetTypeSelectorMobile
      isBetTypeSelectorDisabled={isBetTypeSelectorDisabled}
      sport={sport}
      type="live-today"
      title={`${capitalize(sport)}/Live & Today`}
    >
      <div className="mb-10 desktop:ml-3 desktop:mr-3">
        <SportTableMobile title={liveTitle} type="live" sport={sport} />
      </div>
      <div className="mb-10 desktop:ml-3 desktop:mr-3">
        <SportTableMobile title={todayTitle} type="today" sport={sport} />
      </div>
    </BetTypeSelectorMobile>
  );
};

export default SportTableLiveAndToday;
