import { Row } from "@tanstack/react-table";
import { FormattedFeed } from "../../types/feed";
import SpecialComponent from "./Special";

export const SpecialPriceComponent = ({ row }: { row: Row<FormattedFeed> }) => {
  const sportActivate = ["football"];
  const isActivete = sportActivate.includes(row.original.sport);

  if (
    row.original.betPrice_totol !== row.original.betPrice_order + 1 ||
    !isActivete
  )
    return <></>;

  return (
    <tr>
      <th colSpan={10} className="text-left special">
        <SpecialComponent
          leagueId={row.original.feed_k}
          matchId={row.original.match_k}
          sportType={row.original.sport}
          tableType={row.original.tableType}
          homeName={row.original.match_n}
          awayName={row.original.match_n}
          leagueName={row.original.feed_nn}
          sport={row.original.sport}
          priceCount={row.original.match_special_count}
        />
      </th>
    </tr>
  );
};
