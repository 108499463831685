import { NumberOfMatchType } from "../../types/feed";
import { CONFIG } from "../../config";

export type SportList = {
  name: string;
  icon: string;
  keyData?: (keyof NumberOfMatchType)[]; // key of api data
  isComingSoon?: boolean;
  routeTo?:
    | "football"
    | "basketball"
    | "cricket"
    | "volleyball"
    | "tabletennis"
    | "handball"
    | "icehockey"
    | "tennis"
    | "snooker"
    | "baseball"
    | "rugby"
    | "boxing"
    | "americanfootball"
    | "badminton"
    | "combo-step"
    | "football-parlay"
    | "boxing-parlay";
  collapse?: {
    name: string;
    routeTo: "live-today" | "live" | "today" | "early" | "all";
    keyData: (keyof NumberOfMatchType)[]; // key of api data
  }[];
};

export const SPORT_LISTS: SportList[] =
  CONFIG.ENV === "develop"
    ? [
        {
          name: "Football",
          icon: `${process.env.PUBLIC_URL}/svg/soccer.svg`,
          keyData: ["fbAll"],
          routeTo: "football",
          collapse: [
            {
              name: "Live / Today",
              routeTo: "live-today",
              keyData: ["fbLive", "fbToday"],
            },
            {
              name: "Live",
              routeTo: "live",
              keyData: ["fbLive"],
            },
            {
              name: "Today",
              routeTo: "today",
              keyData: ["fbToday"],
            },
            {
              name: "Early",
              routeTo: "early",
              keyData: ["fbEarly"],
            },
          ],
        },
        {
          name: "Basketball",
          icon: `${process.env.PUBLIC_URL}/svg/basketball.svg`,
          keyData: ["bsAll"],
          routeTo: "basketball",
          collapse: [
            {
              name: "Live / Today",
              routeTo: "live-today",
              keyData: ["bsAll"],
            },
            {
              name: "Live",
              routeTo: "live",
              keyData: ["bsLive"],
            },
            {
              name: "Today",
              routeTo: "today",
              keyData: ["bsToday"],
            },
          ],
        },
        {
          name: "Combo",
          icon: `${process.env.PUBLIC_URL}/svg/combo.svg`,
          keyData: ["comboStep"],
          routeTo: "combo-step",
        },
        {
          name: "Volleyball",
          icon: `${process.env.PUBLIC_URL}/svg/volleyball.svg`,
          // isComingSoon: true,
          keyData: ["vbAll"],
          routeTo: "volleyball",
          collapse: [
            {
              name: "Live / Today",
              routeTo: "live-today",
              keyData: ["vbAll"],
            },
            {
              name: "Live",
              routeTo: "live",
              keyData: ["vbLive"],
            },
            {
              name: "Today",
              routeTo: "today",
              keyData: ["vbToday"],
            },
          ],
        },
        {
          name: "Cricket",
          icon: `${process.env.PUBLIC_URL}/svg/combo.svg`,
          // isComingSoon: true,
          keyData: ["cricketAll"],
          routeTo: "cricket",
          collapse: [
            {
              name: "Live / Today",
              routeTo: "live-today",
              keyData: ["cricketAll"],
            },
            {
              name: "Live",
              routeTo: "live",
              keyData: ["cricketLive"],
            },
            {
              name: "Today",
              routeTo: "today",
              keyData: ["cricketToday"],
            },
          ],
        },
        {
          name: "Tennis",
          icon: `${process.env.PUBLIC_URL}/svg/tennis-ball.svg`,
          // isComingSoon: true,
          keyData: ["tnAll"],
          routeTo: "tennis",
          collapse: [
            {
              name: "Live / Today",
              routeTo: "live-today",
              keyData: ["tnLive", "tnToday"],
            },
            {
              name: "Live",
              routeTo: "live",
              keyData: ["tnLive"],
            },
            {
              name: "Today",
              routeTo: "today",
              keyData: ["tnToday"],
            },
          ],
        },
        {
          name: "TableTennis",
          icon: `${process.env.PUBLIC_URL}/svg/table-tennis.svg`,
          // isComingSoon: true,
          keyData: ["ttAll"],
          routeTo: "tabletennis",
          collapse: [
            {
              name: "Live / Today",
              routeTo: "live-today",
              keyData: ["ttLive", "ttToday"],
            },
            {
              name: "Live",
              routeTo: "live",
              keyData: ["ttLive"],
            },
            {
              name: "Today",
              routeTo: "today",
              keyData: ["ttToday"],
            },
          ],
        },
        {
          name: "Handball",
          icon: `${process.env.PUBLIC_URL}/svg/handball.svg`,
          // isComingSoon: true,
          keyData: ["hbAll"],
          routeTo: "handball",
          collapse: [
            {
              name: "Live / Today",
              routeTo: "live-today",
              keyData: ["hbLive", "hbToday"],
            },
            {
              name: "Live",
              routeTo: "live",
              keyData: ["hbLive"],
            },
            {
              name: "Today",
              routeTo: "today",
              keyData: ["hbToday"],
            },
          ],
        },
        {
          name: "Icehockey",
          icon: `${process.env.PUBLIC_URL}/svg/icehockey.svg`,
          // isComingSoon: true,
          keyData: ["ihAll"],
          routeTo: "icehockey",
          collapse: [
            {
              name: "Live / Today",
              routeTo: "live-today",
              keyData: ["ihLive", "ihToday"],
            },
            {
              name: "Live",
              routeTo: "live",
              keyData: ["ihLive"],
            },
            {
              name: "Today",
              routeTo: "today",
              keyData: ["ihToday"],
            },
          ],
        },
        {
          name: "Snooker",
          icon: `${process.env.PUBLIC_URL}/svg/snooker.svg`,
          // isComingSoon: true,
          keyData: ["snAll"],
          routeTo: "snooker",
          collapse: [
            {
              name: "Live / Today",
              routeTo: "live-today",
              keyData: ["snLive", "snToday"],
            },
            {
              name: "Live",
              routeTo: "live",
              keyData: ["snLive"],
            },
            {
              name: "Today",
              routeTo: "today",
              keyData: ["snToday"],
            },
          ],
        },
        {
          name: "Baseball",
          icon: `${process.env.PUBLIC_URL}/svg/baseball.svg`,
          // isComingSoon: true,
          keyData: ["baseballAll"],
          routeTo: "baseball",
          collapse: [
            {
              name: "Live / Today",
              routeTo: "live-today",
              keyData: ["baseballLive", "baseballToday"],
            },
            {
              name: "Live",
              routeTo: "live",
              keyData: ["baseballLive"],
            },
            {
              name: "Today",
              routeTo: "today",
              keyData: ["baseballToday"],
            },
            {
              name: "Early",
              routeTo: "early",
              keyData: ["baseballEarly"],
            },
          ],
        },
        {
          name: "Rugby",
          icon: `${process.env.PUBLIC_URL}/svg/rugby.svg`,
          // isComingSoon: true,
          keyData: ["rugbyAll"],
          routeTo: "rugby",
          collapse: [
            {
              name: "Live / Today",
              routeTo: "live-today",
              keyData: ["rugbyLive", "rugbyToday"],
            },
            {
              name: "Live",
              routeTo: "live",
              keyData: ["rugbyLive"],
            },
            {
              name: "Today",
              routeTo: "today",
              keyData: ["rugbyToday"],
            },
            {
              name: "Early",
              routeTo: "early",
              keyData: ["rugbyEarly"],
            },
          ],
        },
        {
          name: "AmericanFB",
          icon: `${process.env.PUBLIC_URL}/svg/americanfootball.svg`,
          // isComingSoon: true,
          keyData: ["americanFootballAll"],
          routeTo: "americanfootball",
          collapse: [
            {
              name: "Live / Today",
              routeTo: "live-today",
              keyData: ["americanFootballLive", "americanFootballToday"],
            },
            {
              name: "Live",
              routeTo: "live",
              keyData: ["americanFootballLive"],
            },
            {
              name: "Today",
              routeTo: "today",
              keyData: ["americanFootballToday"],
            },
            {
              name: "Early",
              routeTo: "early",
              keyData: ["americanFootballEarly"],
            },
          ],
        },
        {
          name: "Badminton",
          icon: `${process.env.PUBLIC_URL}/svg/badminton.svg`,
          // isComingSoon: true,
          keyData: ["badmintonAll"],
          routeTo: "badminton",
          collapse: [
            {
              name: "Live / Today",
              routeTo: "live-today",
              keyData: ["badmintonLive", "badmintonToday"],
            },
            {
              name: "Live",
              routeTo: "live",
              keyData: ["badmintonLive"],
            },
            {
              name: "Today",
              routeTo: "today",
              keyData: ["badmintonToday"],
            },
            {
              name: "Early",
              routeTo: "early",
              keyData: ["badmintonEarly"],
            },
          ],
        },
        {
          name: "Boxing",
          icon: `${process.env.PUBLIC_URL}/svg/boxing-gloves.svg`,
          // isComingSoon: true,
          keyData: ["muayAll"],
          routeTo: "boxing",
          collapse: [
            {
              name: "ทั้งหมด",
              routeTo: "all",
              keyData: ["muayAll"],
            },
            {
              name: "สดตอนนี้",
              routeTo: "live",
              keyData: ["muayLive"],
            },
            {
              name: "วันนี้",
              routeTo: "today",
              keyData: ["muayToday"],
            },
          ],
        },
        // {
        //   name: "มวย พาร์เลย์",
        //   icon: `${process.env.PUBLIC_URL}/svg/combo.svg`,
        //   isComingSoon: true,
        //   // keyData: ["muayParlay"],
        //   // routeTo: "boxing-parlay",
        // },
        // {
        //   name: "บอล พาร์เลย์",
        //   icon: `${process.env.PUBLIC_URL}/svg/combo.svg`,
        //   keyData: ["mixParlay"],
        //   routeTo: "football-parlay",
        // },
      ]
    : [
        {
          name: "Football",
          icon: `${process.env.PUBLIC_URL}/svg/soccer.svg`,
          keyData: ["fbAll"],
          routeTo: "football",
          collapse: [
            {
              name: "Live / Today",
              routeTo: "live-today",
              keyData: ["fbLive", "fbToday"],
            },
            {
              name: "Live",
              routeTo: "live",
              keyData: ["fbLive"],
            },
            {
              name: "Today",
              routeTo: "today",
              keyData: ["fbToday"],
            },
            {
              name: "Early",
              routeTo: "early",
              keyData: ["fbEarly"],
            },
          ],
        },
        {
          name: "Basketball",
          icon: `${process.env.PUBLIC_URL}/svg/basketball.svg`,
          keyData: ["bsAll"],
          routeTo: "basketball",
          collapse: [
            {
              name: "Live / Today",
              routeTo: "live-today",
              keyData: ["bsAll"],
            },
            {
              name: "Live",
              routeTo: "live",
              keyData: ["bsLive"],
            },
            {
              name: "Today",
              routeTo: "today",
              keyData: ["bsToday"],
            },
          ],
        },
        {
          name: "Combo",
          icon: `${process.env.PUBLIC_URL}/svg/combo.svg`,
          keyData: ["comboStep"],
          routeTo: "combo-step",
        },
        {
          name: "Volleyball",
          icon: `${process.env.PUBLIC_URL}/svg/volleyball.svg`,
          isComingSoon: true,
        },
        {
          name: "Cricket",
          icon: `${process.env.PUBLIC_URL}/svg/combo.svg`,
          isComingSoon: true,
        },
        {
          name: "Tennis",
          icon: `${process.env.PUBLIC_URL}/svg/tennis-ball.svg`,
          isComingSoon: true,
        },
        {
          name: "TableTennis",
          icon: `${process.env.PUBLIC_URL}/svg/table-tennis.svg`,
          isComingSoon: true,
        },
        {
          name: "Handball",
          icon: `${process.env.PUBLIC_URL}/svg/handball.svg`,
          isComingSoon: true,
        },
        {
          name: "Icehockey",
          icon: `${process.env.PUBLIC_URL}/svg/icehockey.svg`,
          isComingSoon: true,
        },
        {
          name: "Snooker",
          icon: `${process.env.PUBLIC_URL}/svg/snooker.svg`,
          isComingSoon: true,
        },
        {
          name: "Baseball",
          icon: `${process.env.PUBLIC_URL}/svg/baseball.svg`,
          isComingSoon: true,
        },
        {
          name: "Rugby",
          icon: `${process.env.PUBLIC_URL}/svg/rugby.svg`,
          isComingSoon: true,
        },
        {
          name: "AmericanFB",
          icon: `${process.env.PUBLIC_URL}/svg/americanfootball.svg`,
          isComingSoon: true,
        },
        {
          name: "Badminton",
          icon: `${process.env.PUBLIC_URL}/svg/badminton.svg`,
          isComingSoon: true,
        },
        {
          name: "Boxing",
          icon: `${process.env.PUBLIC_URL}/svg/boxing-gloves.svg`,
          isComingSoon: true,
        },
      ];
