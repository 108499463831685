import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import Date from "./Date";
import MatchName from "./MatchName";
import HDP from "./HDP";
import OU from "./OU";
import X12 from "./X12";
import ML from "./ML";

import { FormattedFeed } from "../../../types/feed";

const columnHelper = createColumnHelper<FormattedFeed>();

export const COLUMNS: ColumnDef<FormattedFeed>[] = [
  {
    header: "Date",
    accessorKey: "match_d",
    cell: Date,
    minSize: 100,
  },
  {
    header: "Competition",
    accessorKey: "match_n",
    cell: MatchName,
    minSize: 200,
  },
  columnHelper.group({
    header: "Full-time",
    columns: [
      {
        id: "FULL_TIME_HDP",
        header: "HDP",
        accessorKey: "betPrice_ah",
        cell: HDP,
        minSize: 100,
      },
      {
        id: "FULL_TIME_OU",
        header: "OU",
        accessorKey: "betPrice_ou",
        cell: OU,
        minSize: 100,
      },
      {
        id: "FULL_TIME_X12",
        header: "1X2",
        accessorKey: "betPrice_x12",
        cell: X12,
        minSize: 100,
      },
    ],
  }),
  columnHelper.group({
    header: "Half-time",
    columns: [
      {
        id: "HALF_TIME_HDP",
        header: "HDP",
        accessorKey: "betPrice_ah1st",
        cell: HDP,
      },
      {
        id: "HALF_TIME_OU",
        header: "OU",
        accessorKey: "betPrice_ou1st",
        cell: OU,
      },
      {
        id: "HALF_TIME_X12",
        header: "1X2",
        accessorKey: "betPrice_x121st",
        cell: X12,
      },
    ],
  }),
];

export const COLUMNS_WITH_ML: ColumnDef<FormattedFeed>[] = [
  {
    header: "Date",
    accessorKey: "match_d",
    cell: Date,
  },
  {
    header: "Competition",
    accessorKey: "match_n",
    cell: MatchName,
  },
  columnHelper.group({
    header: "Full-time",
    columns: [
      {
        id: "FULL_TIME_HDP",
        header: "HDP",
        accessorKey: "betPrice_ah",
        cell: HDP,
      },
      {
        id: "FULL_TIME_OU",
        header: "OU",
        accessorKey: "betPrice_ou",
        cell: OU,
      },
      {
        id: "FULL_TIME_ML",
        header: "ML",
        accessorKey: "betPrice_ml",
        cell: ML,
      },
    ],
  }),
  columnHelper.group({
    header: "Half-time",
    columns: [
      {
        id: "HALF_TIME_HDP",
        header: "HDP",
        accessorKey: "betPrice_ah1st",
        cell: HDP,
      },
      {
        id: "HALF_TIME_OU",
        header: "OU",
        accessorKey: "betPrice_ou1st",
        cell: OU,
      },
      {
        id: "FULL_TIME_ML1ST",
        header: "ML",
        accessorKey: "betPrice_ml1st",
        cell: ML,
      },
    ],
  }),
];

export const COLUMNS_FULLTIME_ONLY: ColumnDef<FormattedFeed>[] = [
  {
    header: "Date",
    accessorKey: "match_d",
    cell: Date,
  },
  {
    header: "Competition",
    accessorKey: "match_n",
    cell: MatchName,
  },
  columnHelper.group({
    header: "Full-time",
    columns: [
      {
        id: "FULL_TIME_HDP",
        header: "HDP",
        accessorKey: "betPrice_ah",
        cell: HDP,
      },
      {
        id: "FULL_TIME_OU",
        header: "OU",
        accessorKey: "betPrice_ou",
        cell: OU,
      },
      {
        id: "FULL_TIME_X12",
        header: "1X2",
        accessorKey: "betPrice_x12",
        cell: X12,
      },
    ],
  }),
];

export const COLUMNS_FULLTIME_ONLY_WITH_ML: ColumnDef<FormattedFeed>[] = [
  {
    header: "Date",
    accessorKey: "match_d",
    cell: Date,
  },
  {
    header: "Competition",
    accessorKey: "match_n",
    cell: MatchName,
  },
  columnHelper.group({
    header: "Full-time",
    columns: [
      {
        id: "FULL_TIME_HDP",
        header: "HDP",
        accessorKey: "betPrice_ah",
        cell: HDP,
      },
      {
        id: "FULL_TIME_OU",
        header: "OU",
        accessorKey: "betPrice_ou",
        cell: OU,
      },
      {
        id: "FULL_TIME_ML",
        header: "ML",
        accessorKey: "betPrice_ml",
        cell: ML,
      },
    ],
  }),
];
